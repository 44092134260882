import React from "react";
import { motion } from "framer-motion";
import { Container, Title, P1 } from "../../Common/Styles/Styles";
import { Colors } from "../../Common/Constants/constants";
import Footer from "../Components/Footer/Footer";

const About = () => {
  const aboutInfo = {
    title: "About Classique Scents SA",
    description: `Classique Scents SA was established in 2021 and embodies the pinnacle of
      luxury perfumery. Our mission is to elevate the art of fragrance,
      delivering exceptional, high-standard scents that surpass industry
      benchmarks.`,
  };

  const features = [
    {
      title: "Exquisite Quality",
      description: `Our perfumes boast premium French fragrance oils, carefully selected
      for their intensity and longevity.`,
    },
    {
      title: "Timeless Fragrances",
      description: `Masterfully blended to evoke emotions, spark memories, and leave an
      unforgettable signature.`,
    },
    {
      title: "Affordable Luxury",
      description: `Experience high-end perfumery without the hefty price tag.`,
    },
    {
      title: "Exclusive Blends",
      description: `Unique fragrances crafted to surpass industry standards.`,
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.8 }}
    >
      <Container
        flexDirection="column"
        alignItems="center"
        padding="20px">

        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        >
          <Container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding="32px"
            backgroundColor="#fff"
            borderRadius="16px"
            boxShadow="0 10px 20px rgba(0, 0, 0, 0.2)"
            maxWidth="900px"
            textAlign="center"
          >
            <Title
              color={Colors.BLACK}
              fontSize="2.5rem"
              margin="0 0 16px"
            >
              {aboutInfo.title}
            </Title>
            <P1 fontSize="1.2rem" color={Colors.BLACK} margin="0">
              {aboutInfo.description}
            </P1>
          </Container>
        </motion.div>

        {/* Features Section */}
        <Container
          flexDirection="column"
          alignItems="center"
          margin="32px 0"
          width="100%"
        >
          {features.map((feature, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.05 }}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                duration: 0.8,
                delay: 0.2 + index * 0.2,
                type: "spring",
              }}
              style={{
                backgroundColor: Colors.ANTIQUEGOLD,
                padding: "24px",
                borderRadius: "12px",
                marginBottom: "16px",
                textAlign: "center",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)",
              }}
            >
              <Title
                fontSize="1.5rem"
                color={Colors.BLACK}
                margin="0 0 8px 0"
              >
                {feature.title}
              </Title>
              <P1 fontSize="1.1rem" color={Colors.BLACK}>
                {feature.description}
              </P1>
            </motion.div>
          ))}
        </Container>

        {/* Footer */}
        <Footer />
      </Container>
    </motion.div>
  );
};

export default About;
