import React from 'react';

import {
  Container,
  Title,
  P1,
} from '../../../Common/Styles/Styles';

const Cancel = () => {

  return (
    <Container
      backgroundColor="#dc3545"
      padding={"40px"}
      height={'100vh'}
      justifyContent={"flex-start"}
      flexDirection={"column"}
      alignItems="center">

      <Title
        color="#fff" 
        fontSize="3rem">

        Payment Cancelled
      </Title>

      <P1
        color="#fff"
        fontSize="1.5rem"
        margin="20px 0">

        Your payment has been cancelled. Please try again or contact support if you need assistance.
      </P1>

    </Container>
  );
};

export default Cancel;
