// Validate Firstname (Alphabets only, 1 to 50 characters)
export function validateName(firstName) {
  const regex = /^[A-Za-z]{1,50}$/; // Only alphabets, length between 1 and 50
  return regex.test(firstName);
}

// Validate Email (standard email format)
export function validateEmail(email) {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/; // Basic email format
  return regex.test(email);
}

// Validate Phone Number (South Africa, valid numbers start with +27 or 0, followed by 9 digits)
export function validatePhoneNumber(phone) {
  const regex = /^(?:\+27|0)[\d]{9}$/; // Starts with +27 or 0, followed by 9 digits
  return regex.test(phone);
}

// Validate Address (must be a non-empty string, length between 5 and 200 characters)
export function validateAddress(address) {
  const regex = /^.{5,200}$/; // Must be between 5 and 200 characters long
  return regex.test(address);
}