import React from 'react';

import {
  Container,
  Title,
  P1,
} from '../../../Common/Styles/Styles';

const ThankYou = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const transaction = urlParams.get('transaction');
  const amount = urlParams.get('amount');

  fetch('https://api.emailservice.com/send', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer YOUR_API_KEY',
    },
    body: JSON.stringify({
      to: email,
      subject: 'Payment Confirmation',
      text: `Thank you for your payment! Transaction: ${transaction}, Amount: ${amount}`,
    }),
  })
    .then((response) => response.json())
    .then((data) => console.log('Email sent successfully:', data))
    .catch((error) => console.error('Error sending email:', error));

  return (
    <Container
      backgroundColor="#28a745"
      padding="40px"
      flexDirection={"column"}
      justifyContent={"flex-start"}
      height={'100vh'}
      alignItems="center">

      <Title
        color="#fff"
        fontSize="3rem">

        Thank You for Your Payment!
      </Title>

      <P1
        color="#fff"
        fontSize="1.5rem"
        margin="20px 0">

        Your payment was successful. We appreciate your business.
      </P1>

      <P1
        color="#fff"
        fontSize="1.2rem">

        Transaction Reference: #1234567890
      </P1>
    </Container>
  );
};

export default ThankYou;
