
//Use this to name colors : https://colornamer.robertcooper.me/
export const Colors = {
  CEREBRALGREY: '#CCC',
  WHITE: '#FFFFFF',
  BLACK: '#000',
  LONDONRAIN: '#0056b3',
  DEVILSGRASS: '#4CAF50',
  RHAPSODYINBLUE: '#002244',
  PICTUREBOOKGREEN: '#037B4A',
  STEAM: '#DDD',
  AZURE: '#007BFF',
  RED: '#FF0000',
  COASTALBREEZE: '#e0f7fa',
  HAWKTURQUOISE: '#00796b',
  URBANGREEN: '#004d40',
  LIGHTBLUE: '#add8e6',
  ANTIQUEGOLD: '#DAA520'
};

export const Breakpoints = {
  mobile: '480px',
  tablet: '768px',
  desktop: '1024px'
};

export const environment = {
  localhost: 'http://localhost:4000',
  uiHost: './Assets/images/'
};

export const address = {
  streetNumber: '',
  streetName: '',
  suburb: '',
  city: '',
  province: '',
  postalCode: ''
};

export const provinces = [
  'Eastern Cape',
  'Free State',
  'Gauteng',
  'KwaZulu-Natal',
  'Limpopo',
  'Mpumalanga',
  'North West',
  'Northern Cape',
  'Western Cape'
];


