import React, {
  useEffect,
  useState
} from "react";

import {
  Container,
  Title,
  Button,
  P1,
  Form,
  Label,
  Input,
} from "../../../Common/Styles/Styles";

import { Colors } from "../../../Common/Constants/constants";

import {
  endpoint,
  payFastConfig
} from "../../../Config/config";

import {
  validateAddress,
  validateEmail,
  validateName,
  validatePhoneNumber,
} from "../../../Common/Utilities/validations";

import { useCart } from "../../../CartContext";

import { useNavigate } from "react-router-dom";

import { routes } from "../../../Common/Routes/Routes";

const Checkout = () => {

  const { cart } = useCart();
  const navigate = useNavigate()
  const [userDetailsData, setUserDetailsData] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    cell_number: "",
    address: "",
  });
  const [errors, setErrors] = useState({});
  const pack = parseInt(localStorage.getItem('pack'))
  const cartCount = cart.reduce((sum, item) => sum + item.quantity, 0);


  const totalPrice = cart.reduce(
    (sum, item) => sum + item.price * item.quantity,
    100
  );

  let amount;

  const packPrices = {
    15: 2500, 
    10: 1500, 
  };
  
  if (pack && packPrices[pack]) {

      amount = (cartCount / pack) * packPrices[pack] + 100;
  } else {

      amount = parseInt(totalPrice);
  }

  useEffect(() => {

    if (totalPrice < 101) {
      navigate(routes.home)
    }

  }, [totalPrice])

  const validateForm = () => {

    const { first_name, last_name, email_address, cell_number, address } =
      userDetailsData;
    const newErrors = {};

    if (!validateName(first_name)) newErrors.first_name = "First name is invalid.";
    if (!validateName(last_name)) newErrors.last_name = "Last name is invalid.";
    if (!validateEmail(email_address)) newErrors.email_address = "Email is invalid.";
    if (!validatePhoneNumber(cell_number)) newErrors.cell_number = "Phone number is invalid.";
    if (!validateAddress(address)) newErrors.address = "Address is invalid.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setUserDetailsData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderError = (field) =>

    errors[field] && (
      <P1 color="red"
        textAlign={"center"}
        fontSize="12px"
        margin="0">
        {errors[field]}
      </P1>
    );

  const createPayFastForm = () => {

    localStorage.clear()

    if (!validateForm()) return;

    const payFastData = {
      merchant_id: payFastConfig.merchantId,
      merchant_key: payFastConfig.merchantKey,
      amount: parseInt(amount),
      item_name: "Order Summary",
      item_description: "Perfume purchase",
      quantity: cart.reduce((sum, item) => sum + item.quantity, 0),
      return_url: payFastConfig.returnUrl,
      cancel_url: payFastConfig.cancelUrl,
      notify_url: payFastConfig.notifyUrl,
      email_address: userDetailsData.email_address,
      name_first: userDetailsData.first_name,
      name_last: userDetailsData.last_name,
      cell_number: userDetailsData.cell_number,
    };

    const form = document.createElement("form");
    form.method = "POST";
    form.action = endpoint;

    Object.keys(payFastData).forEach((key) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = payFastData[key];
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
  };

  return (

    <Container
      flexDirection="column"
      backgroundColor={Colors.ANTIQUEGOLD}>

      <Container
        width="100vw"
        padding="12px 0">

        <Title
          margin="0"
          color={Colors.WHITE}>

          Checkout
        </Title>
      </Container>

      <Container
        backgroundColor={Colors.AZURE}
        padding="16px"
        borderRadius="16px"
        width="70vw"
        mobileWidth="100vw"
        gap="12px"
        justifyContent="space-evenly">

        <Container
          flexDirection="column"
          borderRadius="8px"
          backgroundColor={Colors.BLACK}
          padding="52px 12px">

          <Title
            margin="0"
            color={Colors.WHITE}>

            Your Order
          </Title>

          <P1
            color={Colors.WHITE}
            fontSize="1rem"
            margin="12px 0">

            (Please note that there is a shipping fee of R100, which will be added to the order.)
          </P1>

          {cart.map((item) => (

            <div key={item.id}
              style={{ marginBottom: "10px" }}>


              {!pack && <P1
                color={Colors.WHITE}
                fontSize="1rem">

                {item.name}: R {item.price.toFixed(2)} x {item.quantity} = R{" "}
                {(item.price * item.quantity).toFixed(2)}
              </P1>
              }

              {pack && <P1
                color={Colors.WHITE}
                fontSize="1rem">

                {item.name} x {item.quantity} = R{" "}
                {amount - 100}
              </P1>
              }
            </div>


          ))}

          {!pack && <P1
            color={Colors.WHITE}
            fontSize="1rem"
            margin="12px 0">

            Total Amount: R {totalPrice.toFixed(2)}
          </P1>
          }

          {pack && <P1
            color={Colors.WHITE}
            fontSize="1rem"
            margin="12px 0">

            Total Amount: R {amount}

          </P1>
          }
        </Container>

        <Container
          padding="12px"
          flexDirection="column"
          borderRadius="8px"
          backgroundColor={Colors.BLACK}>

          <Title
            margin="0 12px"
            color={Colors.WHITE}>

            Personal Details
          </Title>

          <P1
            color={Colors.WHITE}
            fontSize="1rem"
            margin="12px 0">

            Please make sure your details are correct.
          </P1>
          <Form>

            {[
              { label: "First Name", name: "first_name", type: "text" },
              { label: "Last Name", name: "last_name", type: "text" },
              { label: "Email", name: "email_address", type: "email" },
              { label: "Phone", name: "cell_number", type: "tel" },
              { label: "Address", name: "address", type: "text" },
            ].map(({ label, name, type }) => (
              <Container
                padding={"10px 16px"}
                width={"100%"}
                flexDirection={"column"}
                alignItems={"flex-end"}
                key={name}>

                <Label
                  fontSize="1rem"
                  textAlign="left"
                  width="100%">

                  {label}:
                  <Input
                    type={type}
                    name={name}
                    placeholder={`Enter your ${label.toLowerCase()}`}
                    fontSize="1rem"
                    borderRadius="4px"
                    padding="10px"
                    width="100%"
                    value={userDetailsData[name]}
                    onChange={handleInputChange}
                    required />
                </Label>

                {renderError(name)}
              </Container>
            ))}

            <Container
              width={"100%"}
              flexDirection={"column"}
              alignItems={"center"}>

              <Button
                type={"button"}
                backgroundColor={"#4CAF50"}
                color={"#fff"}
                fontSize={"1rem"}
                borderRadius={"4px"}
                onClick={createPayFastForm}>

                Proceed to PayFast Payment
              </Button>
            </Container>


          </Form>
        </Container>
      </Container>
    </Container>
  );
};

export default Checkout;
