import React from 'react';

import {
  Container,
  Title,
  P1,
  List,
  ListItem,
  Highlight,
} from '../../Common/Styles/Styles';

import Footer from '../Components/Footer/Footer';

const TermsAndConditions = () => {

  return (

    <Container
      flexDirection={'column'}
      width="100%"
      margin="auto"
      backgroundColor="#f9f9f9"
      borderRadius="12px"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)">

      <Title
        fontSize="2.5rem"
        fontWeight="bold"
        color="#333"
        textAlign="center"
        marginBottom="20px">

        Terms and Conditions
      </Title>

      <Container
        padding="0">

        <P1
          fontSize="1rem"
          lineHeight="1.6"
          color="#555">

          Welcome to
          <Highlight
            color="#007BFF">
            [Classique Scents]
          </Highlight>.

          By using our website, you agree to comply with the following terms and conditions. Please read them carefully before proceeding.
        </P1>
      </Container>

      <Container
        flexDirection={'column'}
        padding="20px 0"
        borderBottom="1px solid #ddd">

        <Title
          fontSize="1.8rem"
          fontWeight="bold"
          color="#444"
          marginBottom="10px">

          1. Usage Guidelines
        </Title>

        <P1
          fontSize="1rem"
          lineHeight="1.6"
          color="#555">

          To ensure a safe and enjoyable experience, users must:
        </P1>

        <List>

          <ListItem
            fontSize="1rem"
            lineHeight="1.6"
            color="#555">

            Refrain from sharing illegal, harmful, or abusive content.
          </ListItem>

          <ListItem
            fontSize="1rem"
            lineHeight="1.6"
            color="#555">

            Respect the intellectual property rights of others.
          </ListItem>

          <ListItem
            fontSize="1rem"
            lineHeight="1.6"
            color="#555">

            Use the website in compliance with local and international laws.
          </ListItem>
        </List>
      </Container>

      <Container
        flexDirection={'column'}
        padding="20px 0"
        borderBottom="1px solid #ddd">

        <Title
          fontSize="1.8rem"
          fontWeight="bold"
          color="#444"
          marginBottom="10px">

          2. Privacy Policy
        </Title>

        <P1
          fontSize="1rem"
          lineHeight="1.6"
          color="#555">

          Your privacy is important to us. We collect, use, and protect your information as outlined in our
          <Highlight color="#007BFF"> Privacy Policy</Highlight>.
        </P1>
      </Container>

      <Container
        flexDirection={'column'}
        padding="20px 0"
        borderBottom="1px solid #ddd">

        <Title
          fontSize="1.8rem"
          fontWeight="bold"
          color="#444"
          marginBottom="10px">

          3. Limitations of Liability
        </Title>

        <P1
          fontSize="1rem"
          lineHeight="1.6"
          color="#555">

          Our company is not responsible for damages resulting from:
        </P1>
        <List>
          <ListItem
            fontSize="1rem"
            lineHeight="1.6"
            color="#555">

            Service interruptions or downtime.
          </ListItem>

          <ListItem
            fontSize="1rem"
            lineHeight="1.6"
            color="#555">

            User errors or misuse of the platform.
          </ListItem>

          <ListItem
            fontSize="1rem"
            lineHeight="1.6"
            color="#555">

            External links or third-party content accessed through our site.
          </ListItem>
        </List>
      </Container>

      <Container
        flexDirection={'column'}
        padding="20px 0"
        borderBottom="1px solid #ddd">

        <Title
          fontSize="1.8rem"
          fontWeight="bold"
          color="#444"
          marginBottom="10px">

          4. Refund and Returns Policy
        </Title>

        <P1
          fontSize="1rem"
          lineHeight="1.6"
          color="#555">

          At Classique Scents SA, we strive to ensure you're completely satisfied with your purchase. If for any reason you're not, we offer a 7-day refund and returns policy.
        </P1>

        <P1
          fontSize="1rem"
          lineHeight="1.6"
          color="#555">

          To be eligible for a return:
        </P1>

        <List>
          <ListItem
            fontSize="1rem"
            lineHeight="1.6"
            color="#555">

            Your item must be in its original condition, unused, and in the original packaging.
          </ListItem>

          <ListItem
            fontSize="1rem"
            lineHeight="1.6"
            color="#555">

            - You must provide a receipt or proof of purchase.
          </ListItem>


        </List>

        <P1
          fontSize="1rem"
          lineHeight="1.6"
          color="red">

          Please note that returns will not be accepted after 7 days from the date of purchase        </P1>
      </Container>

      <Container
        flexDirection={'column'}
        padding="20px 0"
        borderBottom="1px solid #ddd">

        <Title
          fontSize="1.8rem"
          fontWeight="bold"
          color="#444"
          marginBottom="10px">

          5. Modifications to Terms
        </Title>

        <P1
          fontSize="1rem"
          lineHeight="1.6"
          color="#555">

          We reserve the right to update these terms at any time. Significant changes will be communicated through a notice on our website or by email.
        </P1>
      </Container>

      <Container
        flexDirection={'column'}
        padding="20px 0">

        <Title
          fontSize="1.8rem"
          fontWeight="bold"
          color="#444"
          marginBottom="10px">

          6. Contact Information
        </Title>

        <P1
          fontSize="1rem"
          lineHeight="1.6"
          color="#555">
          If you have any questions or concerns, please reach out to us at
          <Highlight color="#007BFF"> support@yourcompany.com</Highlight>.
        </P1>
      </Container>

      <Footer />
    </Container>
  );
};

export default TermsAndConditions;
