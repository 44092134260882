import styled from 'styled-components';

import {
  Link
} from 'react-router-dom';

import {
  Colors,
  Breakpoints
} from '../Constants/constants';

const shouldForwardProp = (prop) => ![
  'backgroundColor',
  'borderRadius',
  'flexDirection',
  'justifyContent',
  'alignItems',
  'textAlign',
  'color',
  'fontSize',
  'fontWeight',
  'lineHeight',
  'cursor',
  'boxShadow',
  'hoverBackgroundColor',
  'hoverBorderRadius',
  'backgroundImg',
  'gap',
  'mobileFlexDirection',
  'mobileFontSize',
  'mobileDisplay',
  'mobileAlignItems',
  'mobileWidth',
  'maxWidth',
  'maxHeight',
  'visible',
  'flexWrap',
  'gridTemplateColumns',
  'justifyItems',
  'textOrientation',
  'active',
  'alignContent',
  'zIndex'].includes(prop);

export const Container = styled.div.withConfig({ shouldForwardProp })`
  width: ${props => props.width};
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};
  max-width: ${props => props.maxWidth};
  font-size: ${props => props.fontSize};
  border-radius: ${props => props.borderRadius};
  display: ${props => props.display || 'flex'};
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  background-image: url(${props => props.backgroundImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: ${props => props.textAlign || 'center'};
  padding: ${props => props.padding};
  margin-top: ${props => props.marginTop};
  margin: ${props => props.margin};
  box-shadow: ${props => props.boxShadow};
  flex: ${props => props.flex};
  gap: ${props => props.gap};
  grid-template-columns: ${props => props.gridTemplateColumns};
  justify-items: ${props => props.justifyItems};
  flex-wrap: ${props => props.flexWrap};
  position: ${props => props.position};
  left: ${props => props.left};
  right: ${props => props.right};
  flex-direction: ${props => props.flexDirection};
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${props => props.justifyContent || 'center'};
  align-content: ${props => props.alignContent};
  overflow-x: hidden;
  overflow-y: hidden;

  @media (max-width: ${Breakpoints.mobile}) {
    flex-direction: ${props => props.mobileFlexDirection || 'column'};
    align-items: ${props => props.mobileAlignItems};
    justify-content: ${props => props.mobileJustifyContent};
    display: ${props => props.mobileDisplay};
    padding: ${props => props.mobilePadding};
    margin: ${props => props.mobileMargin};
    width: ${props => props.mobileWidth};
    gap: ${props => props.mobileGap};
    align-content: ${props => props.mobileAlignContent || 'center'};

  }
`;

export const FeatureItem = styled.div.withConfig({ shouldForwardProp })`
  display: ${props => props.display || 'flex'};
  flex-direction: ${props => props.flexDirection || 'column'};
  align-items: ${props => props.alignItems || 'center'};
  text-align: ${props => props.textAlign || 'center'};
  padding: ${props => props.padding || '16px'};
  background-color: ${props => props.backgroundColor || Colors.LONDONRAIN};
  color: ${props => props.color || Colors.WHITE};
  border: ${props => props.border || 'none'};
  margin: ${props => props.margin};
  border-radius: ${props => props.borderRadius || '4px'};
  max-height: ${props => props.maxHeight || '20%'};
  max-width: ${props => props.maxWidth || '40%'};
  box-shadow: ${props => props.boxShadow || '0 0 20px rgba(0,0,0,0.1)'};
  width: ${props => props.width};

  &:hover {
    cursor: ${props => props.cursor || 'pointer'};
    background-color: ${props => props.hoverBackgroundColor};
  }

  @media (max-width: ${Breakpoints.mobile}) {
    padding: 8px;
    max-width: 100%;
  }
`;

export const Nav = styled.nav.withConfig({ shouldForwardProp })`
  background: ${props => props.background};
  height: ${props => props.height};
  display: ${props => props.display};
  flex-direction: ${props => props.flexDirection || 'column'};
  justify-content: ${props => props.justifyContent};
  padding: ${props => props.padding};
  align-items: ${props => props.alignItems};

  @media (max-width: ${Breakpoints.mobile}) {
    align-items: ${props => props.mobileAlignItems};
    justify-content: ${props => props.mobileJustifyContent};
  }
`;

export const Image = styled.img.withConfig({ shouldForwardProp })`
  border-radius: ${props => props.borderRadius};
  max-width: ${props => props.maxWidth};
  max-height: ${props => props.maxHeight};
  padding: ${props => props.padding};
  height: ${props => props.height || 'fit-content'};

  @media (max-width: ${Breakpoints.mobile}) {
    max-width: 100%;
    height: ${props => props.mobileHeight};

  }
`;

export const Title = styled.h1.withConfig({ shouldForwardProp })`
  color: ${props => props.color || Colors.WHITE};
  margin: ${props => props.margin};
  font-size: ${props => props.fontSize || '3rem'};
  font-weight: ${props => props.fontWeight || '700'};
  line-height: ${props => props.lineHeight || '1.2'};
  text-align: ${props => props.textAlign};

  @media (max-width: ${Breakpoints.mobile}) {
    font-size: 2rem;
  }
`;

export const Form = styled.form.withConfig({ shouldForwardProp })`
  display: ${props => props.display || 'flex'};
  flex-direction: ${props => props.flexDirection || 'column'};
  align-items: ${props => props.alignItems || 'center'};
  justify-content: ${props => props.justifyContent || 'center'};
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: ${props => props.backgroundColor};
  border-radius: ${props => props.borderRadius};
  padding: ${props => props.padding};
  flex-wrap: ${props => props.flexWrap};
  
`;

export const Select = styled.select.withConfig({ shouldForwardProp })`
  display: ${props => props.display || 'flex'};
  width: ${props => props.width};
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};
  max-width: ${props => props.maxWidth};
  font-size: ${props => props.fontSize};
  border-radius: ${props => props.borderRadius};
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems || 'center'};
  padding: ${props => props.padding || '16px'};
  margin: ${props => props.margin || '16px'};
  background-image: url(${props => props.backgroundImg});
  box-shadow: ${props => props.boxShadow};
  gap: ${props => props.gap};
`;

export const ListItem = styled.li.withConfig({ shouldForwardProp })`
  width: ${props => props.width};
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};
  max-width: ${props => props.maxWidth};
  font-size: ${props => props.fontSize};
  border-radius: ${props => props.borderRadius};
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  padding: ${props => props.padding || '16px'};
  margin: ${props => props.margin || '16px'};
`;

export const Option = styled.option.withConfig({ shouldForwardProp })`
  width: ${props => props.width};
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};
  max-width: ${props => props.maxWidth};
  font-size: ${props => props.fontSize};
  border-radius: ${props => props.borderRadius};
  display: ${props => props.display || 'flex'};
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  flex-direction: ${props => props.flexDirection};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems || 'center'};
  padding: ${props => props.padding || '16px'};
  margin: ${props => props.margin || '16px'};
  background-image: url(${props => props.backgroundImg});
  box-shadow: ${props => props.boxShadow};
  gap: ${props => props.gap};
`;

export const Input = styled.input.withConfig({ shouldForwardProp })`
  padding: ${props => props.padding || '10px'};
  margin-bottom: ${props => props.marginBottom};
  border: ${props => props.border || `1px solid ${Colors.CEREBRALGREY}`};
  border-radius: ${props => props.borderRadius || '4px'};
  font-size: ${props => props.fontSize || '16px'};
  text-align: ${props => props.textAlign || 'center'};


  @media (max-width: ${Breakpoints.mobile}) {
    font-size: 14px;
  }
`;

export const Button = styled.button.withConfig({ shouldForwardProp })`
  margin: ${props => props.margin || '16px'};
  padding: ${props => props.padding || '16px'};
  background-color: ${props => props.backgroundColor || Colors.LONDONRAIN};
  color: ${props => props.color || Colors.WHITE};
  border: ${props => props.border || 'none'};
  border-radius: ${props => props.borderRadius || '4px'};
  cursor: ${props => props.cursor || 'pointer'};
  font-size: ${props => props.fontSize || '18px'};

  
  &:hover {
    background-color: ${props => props.hoverBackgroundColor};
  }

  @media (max-width: ${Breakpoints.mobile}) {
    padding: 12px;
    font-size: 16px;
  }
`;

export const Label = styled.label.withConfig({ shouldForwardProp })`
  width: ${props => props.width};
  text-align: ${props => props.textAlign || 'left'};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  color: ${props => props.color || Colors.WHITE};
  background-color: ${props => props.backgroundColor};
  display: ${props => props.display || 'flex'};
  flex-direction: ${props => props.flexDirection };
  justify-content: ${props => props.justifyContent || 'space-between' } ;
  align-items: ${props => props.alignItems || 'space-around'};
  align-content: center;


  @media (max-width: ${Breakpoints.mobile}) {
    margin-bottom: 8px;
  }
`;

export const ErrorMessage = styled.span.withConfig({ shouldForwardProp })`
  color: ${props => props.color};
  font-size: ${props => props.fontSize || '14px'};
  padding: ${props => props.padding || '8px 0'};

  @media (max-width: ${Breakpoints.mobile}) {
    font-size: 12px;
  }
`;

export const NavLink = styled(Link).withConfig({ shouldForwardProp })`
  color: ${props => props.color || Colors.WHITE};
  text-decoration: ${props => props.textDecoration || 'none'};
  padding: ${props => props.padding || '12px'};
  height: ${props => props.height};
  display: ${props => props.display || 'flex'};
  align-items: ${props => props.alignItems || 'center'};
  font-size: ${props => props.fontSize || '21px'};
  transition: ${props => props.transition || 'background-color 0.3s ease'};
  
  &:hover {
    background: ${props => props.hoverBackground};
    border-radius: ${props => props.hoverBorderRadius || '5px'};
  }

  @media (max-width: ${Breakpoints.mobile}) {
    font-size: ${props => props.mobileFontSize || '18px'};
    padding: 8px;
  }
`;

export const P1 = styled.p.withConfig({ shouldForwardProp })`
  font-size: ${props => props.fontSize || '1.5rem'};
  margin-bottom: ${props => props.marginBottom || '30px'};
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  margin: ${props => props.margin || '12px'};
  font-weight: ${props => props.fontWeight};
  text-orientation: ${props => props.textOrientation};
  writing-mode: ${props => props.writingMode};
  text-decoration:${props => props.textDecoration};

  &:hover {
    color: ${props => props.hoverBackground};
  }

  @media (max-width: ${Breakpoints.mobile}) {
    font-size: 1.2rem;
    margin-bottom: ${props => props.marginBottom || '20px'};
  }
`;

export const TextArea = styled.textarea.withConfig({ shouldForwardProp })`
  width: ${props => props.width || '40%'};
  padding: ${props => props.padding || '10px'};
  font-size: ${props => props.fontSize || '1rem'};
  border: ${props => props.border || `1px solid ${Colors.CEREBRALGREY}`};
  border-radius: ${props => props.borderRadius || '5px'};
  color: ${props => props.color};

  @media (max-width: ${Breakpoints.mobile}) {
    width: 100%;
    padding: 8px;
    font-size: 0.9rem;
  }
`;

export const Icon = styled.i.withConfig({ shouldForwardProp })`
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: ${props => props.alignItems || 'center'};
  font-size: ${props => props.fontSize || '2rem'};
  color: ${props => props.color || Colors.WHITE};
  padding: ${props => props.padding || '16px'};
  cursor: ${props => props.cursor || 'pointer'};
  align-items: ${props => props.alignItems};
  background-color: ${props => props.backgroundColor};
  width: ${props => props.width};
  gap: ${props => props.gap};
  margin: ${props => props.margin};

  @media (max-width: ${Breakpoints.mobile}) {
    font-size: 1.5rem;
    padding: 8px;
  }
`;

export const BurgerIcon = styled.i.withConfig({ shouldForwardProp })`
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: ${props => props.alignItems || 'center'};
  font-size: ${props => props.fontSize || '2rem'};
  color: ${props => props.color || Colors.WHITE};
  padding: ${props => props.padding || '16px'};
  cursor: ${props => props.cursor || 'pointer'};
  align-items: ${props => props.alignItems};
  background-color: ${props => props.backgroundColor};
  width: ${props => props.width};
  gap: ${props => props.gap};
  margin: ${props => props.margin};
  display: none;

  @media (max-width: ${Breakpoints.mobile}) {
    font-size: 1.5rem;
    padding: 0 8px 0 0;
    display: block;
  }
`;

export const CarouselContainer = styled.div`
  position: relative;
  width: 50%;
  height: 50%;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: ${Breakpoints.mobile}) {
    width: 95%;
    height: 60%;
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
`;

export const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  left: ${props => props.left};
  right: ${props => props.right};
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  z-index: 10;
  border-radius: 50%;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export const DotsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.backgroundColor};
  margin: 0 5px;
  cursor: pointer;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px 0;
`;

export const Highlight = styled.span`
  color: ${({ color }) => color || '#007BFF'};
  font-weight: bold;
  background-color: ${({ bgColor }) => bgColor || '#EAF4FF'};
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background-color: ${({ hoverBgColor }) => hoverBgColor || '#D6EBFF'};
    color: ${({ hoverColor }) => hoverColor || '#0056b3'};
    transform: scale(1.05); 
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: ${({ bgColor }) => bgColor || '#F9FAFB'};
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;