import { useState } from "react";

import {
  ArrowButton,
  CarouselContainer,
  CarouselImage,
  CarouselWrapper,
  Dot,
  DotsContainer
} from "../../../Common/Styles/Styles";

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {

    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {

    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (index) => {

    setCurrentIndex(index);
  };

  return (

    <CarouselContainer>

      <CarouselWrapper
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}>

        {images.map((img, index) => (
          <CarouselImage
            key={index}
            src={img}
            alt={`Slide ${index + 1}`} />
        ))}
      </CarouselWrapper>

      <ArrowButton
        left={'5px'}
        onClick={prevSlide}>

        &#8249;
      </ArrowButton>

      <ArrowButton
        right={'5px'}
        onClick={nextSlide}>

        &#8250;
      </ArrowButton>

      <DotsContainer>
        
        {images.map((_, index) => (
          <Dot
            key={index}
            backgroundColor={(index === currentIndex) ? 'gray' : 'white'}
            onClick={() => goToSlide(index)}
          />
        ))}
      </DotsContainer>
    </CarouselContainer>
  );
};

export default Carousel;