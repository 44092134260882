import React from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';

import Navbar from './UI/Components/Navigation/Nav';

import Home from './UI/Home/Home';

import {
  routes
} from './Common/Routes/Routes';

import Products from './UI/Products/Products';

import Cart from './UI/Buy/Cart';

import Checkout from './UI/Buy/Journey/Checkout';

import About from './UI/About/About';

import ThankYou from './UI/Buy/Journey/ThankYou';

import Notify from './UI/Buy/Journey/Notify';

import Cancel from './UI/Buy/Journey/Cancel';

import ContactUs from './UI/ContactUs/ContactUs';

import TermsAndConditions from './UI/T&Cs/Terms&Conditions';

import Wholesale from './UI/Buy/Journey/Wholesale';

import { CartProvider } from './CartContext';

const App = () => {

  return (

    <CartProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path={routes.home}
            element={<Home />} />
          <Route path={routes.shop.home}
            element={<Products />} />
          <Route path={routes.shop.cart}
            element={<Cart />} />
          <Route path={routes.shop.checkout}
            element={<Checkout />} />
          <Route path={routes.shop.success}
            element={<ThankYou />} />
          <Route path={routes.shop.cancel}
            element={<Cancel />} />
          <Route path={routes.shop.notify}
            element={<Notify />} />
          <Route path={routes.about}
            element={<About />} />
          <Route path={routes.contact}
            element={<ContactUs />} />
          <Route path={routes.shop.wholesale.home}
            element={<Wholesale />} />
          <Route path={routes.terms}
            element={<TermsAndConditions />} />
          <Route path="*"
            element={<Navigate to={routes.home} />} />
        </Routes>
      </Router>
    </CartProvider>
  );
};

export default App;
