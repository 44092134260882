const baseUrl = '/Assets/perfumes/';
const price = 250;

export const DummyProducts = [
  {
    "id": 1,
    "name": "Azzaro - Wanted",
    "price": price,
    "date": "2023-11-01",
    "image": `${baseUrl}azzaro-wanted.jpg`,
    "description": "Azzaro - Wanted",
    "isDiscount": false
  },
  {
    "id": 2,
    "name": "Baccarat Rouge 540",
    "price": price,
    "date": "2023-10-20",
    "image": `${baseUrl}baccarat-rouge.jpg`,
    "description": "Maison Francis Kurkdjian",
    "isDiscount": false
  },
  {
    "id": 3,
    "name": "Black Opium",
    "price": price,
    "date": "2023-10-15",
    "image": `${baseUrl}black-opium.jpg`,
    "description": "Yves Saint laurent",
    "isDiscount": false
  },
  {
    "id": 4,
    "name": "CHanel",
    "price": price,
    "date": "2023-10-05",
    "image": `${baseUrl}coco-medemoiselle.jpg`,
    "description": "Coco-Medemoiselle",
    "isDiscount": false
  },
  {
    "id": 5,
    "name": "Light BLue",
    "price": 45.99,
    "date": "2023-09-25",
    "image": `${baseUrl}dolce-gabbana-light-blue.jpg`,
    "description": "Dolce-Gabbana",
    "isDiscount": false
  },
  {
    "id": 6,
    "name": "SI",
    "price": 75.99,
    "date": "2023-09-20",
    "image": `${baseUrl}giorgio-armani.jpg`,
    "description": "giorgio-armani",
    "isDiscount": false
  },
  {
    "id": 7,
    "name": "Good Girl",
    "price": 65.99,
    "date": "2023-09-15",
    "image": `${baseUrl}good-girl-carolina-herrera.jpg`,
    "description": "carolina-herrera",
    "isDiscount": false
  },
  {
    "id": 8,
    "name": "lady million",
    "price": 89.99,
    "date": "2023-09-10",
    "image": `${baseUrl}giorgio-armani.jpg`,
    "description": "Paco Robanne",
    "isDiscount": false
  },
  {
    "id": 9,
    "name": "Oud intense",
    "price": 55.99,
    "date": "2023-09-05",
    "image": `${baseUrl}gucci.jpg`,
    "description": "Gucci",
    "isDiscount": false
  },
  {
    "id": 10,
    "name": "Oud for greatness",
    "price": 32.99,
    "date": "2023-08-25",
    "image": `${baseUrl}inito.jpg`,
    "description": "Inito",
    "isDiscount": false
  },
  {
    "id": 11,
    "name": "oud wood",
    "price": 42.99,
    "date": "2023-08-20",
    "image": `${baseUrl}oud-wood.jpg`,
    "description": "tom ford",
    "isDiscount": false
  },
  {
    "id": 12,
    "name": "oud bouquet",
    "price": 29.99,
    "date": "2023-08-15",
    "image": `${baseUrl}lancome-oud-bouquet.jpg`,
    "description": "lancome",
    "isDiscount": false
  },
  {
    "id": 13,
    "name": "les-sables-roses",
    "price": 69.99,
    "date": "2023-08-10",
    "image": `${baseUrl}les-sables-roses-louis-vuitton.jpg`,
    "description": "louis-vuitton",
    "isDiscount": false
  },
  {
    "id": 14,
    "name": "myrrh tonka",
    "price": 55.99,
    "date": "2023-08-05",
    "image": `${baseUrl}myrrh-tonka.jpg`,
    "description": "Jo Malone",
    "isDiscount": false
  },
  {
    "id": 15,
    "name": "issey miyake",
    "price": 45.99,
    "date": "2023-07-25",
    "image": `${baseUrl}issey-miyake.jpg`,
    "description": "issey miyake",
    "isDiscount": false
  },
  {
    "id": 16,
    "name": "one million",
    "price": 35.99,
    "date": "2023-07-20",
    "image": `${baseUrl}one-million.jpg`,
    "description": "paco robanne",
    "isDiscount": false
  },
  {
    "id": 17,
    "name": "oud-satin-mood",
    "price": 55.99,
    "date": "2023-07-15",
    "image": `${baseUrl}oud-satin-mood-maison-francis-kurkdjian.jpg`,
    "description": "maison francis kurkdjian",
    "isDiscount": false
  },
  {
    "id": 18,
    "name": "scandal",
    "price": 47.99,
    "date": "2023-07-10",
    "image": `${baseUrl}scandal.jpg`,
    "description": "Jean Paul Gaultier",
    "isDiscount": false
  },
  {
    "id": 19,
    "name": "tobacco vanilla",
    "price": 62.99,
    "date": "2023-07-05",
    "image": `${baseUrl}tobacco-vanilla.jpg`,
    "description": "Tom Ford",
    "isDiscount": false
  },
  {
    "id": 20,
    "name": "vip212",
    "price": 33.99,
    "date": "2023-06-25",
    "image": `${baseUrl}vip212.jpg`,
    "description": "Carolina Herrera",
    "isDiscount": false
  },
  {
    "id": 21,
    "name": "White Oud",
    "price": 49.99,
    "date": "2023-06-20",
    "image": `${baseUrl}white-oud.jpg`,
    "description": "",
    "isDiscount": false
  }
];

export const imageurls = [
  '/Assets/perfumes/azzaro-wanted.jpg',
  '/Assets/perfumes/baccarat-rouge.jpg',
  '/Assets/perfumes/black-opium.jpg',
  '/Assets/perfumes/coco-medemoiselle.jpg',
  '/Assets/perfumes/dolce-gabbana-light-blue.jpg',
  '/Assets/perfumes/giorgio-armani.jpg',
  '/Assets/perfumes/good-girl-carolina-herrera.jpg',
  '/Assets/perfumes/giorgio-armani.jpg',
  '/Assets/perfumes/gucci.jpg',
  '/Assets/perfumes/inito.jpg',
  '/Assets/perfumes/oud-wood.jpg',
  '/Assets/perfumes/lancome-oud-bouquet.jpg',
  '/Assets/perfumes/les-sables-roses-louis-vuitton.jpg',
  '/Assets/perfumes/myrrh-tonka.jpg',
  '/Assets/perfumes/issey-miyake.jpg',
  '/Assets/perfumes/one-million.jpg',
  '/Assets/perfumes/oud-satin-mood-maison-francis-kurkdjian.jpg',
  '/Assets/perfumes/scandal.jpg',
  '/Assets/perfumes/tobacco-vanilla.jpg',
  '/Assets/perfumes/vip212.jpg',
  '/Assets/perfumes/white-oud.jpg'
]

export const packDetails = {

  "starter-pack": {
    title: "Hustler’s Starter Pack",
    price: "R1500",
    units: "10 units",
    description: "Perfect for beginners looking to start small and grow their channel.",
  },
  "power-pack": {
    title: "Hustler’s Power Pack",
    price: "R2200",
    units: "15 units",
    description: "Ideal for experienced hustlers ready to scale their business.",
  },
};
