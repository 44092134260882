import React, { useEffect } from 'react';

import '@fortawesome/fontawesome-free/css/all.css';

import { motion } from "framer-motion";

import {
  Container,
  Title,
  FeatureItem,
  P1,
  Button,
  Image,
} from '../../Common/Styles/Styles';

import {
  Colors
} from '../../Common/Constants/constants';

import Carousel from '../Components/Carousel/Carousel';

import Footer from '../Components/Footer/Footer';

import { useNavigate } from 'react-router-dom';

import { routes } from '../../Common/Routes/Routes';

import { imageurls } from '../../Common/Mocks/mock';
import { useCart } from '../../CartContext';

const Home = () => {

  const navigate = useNavigate();
  const pack = localStorage.getItem('pack');
  const { clearCart } = useCart(); 

  const navigateShop = (key) => {

    navigate(routes.shop.home);
  };

  useEffect(( ) => {

    if(pack){
      localStorage.clear()
      clearCart()
    }
  },[])

  return (

    <Container
      flexDirection={'column'}>

      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 1 }}
        style={{
          width: "100%",
          backgroundImage: "url('/Assets/images/black-opium.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "50px 20px",
          textAlign: "center",
        }}>

        <Title
          color={Colors.ANTIQUEGOLD}
          fontSize={"3rem"}
          margin={"0 0 20px"}>

          Your Fragrance Journey Begins Here
        </Title>

        <Button
          backgroundColor={Colors.ANTIQUEGOLD}
          onClick={navigateShop}
          padding="12px 24px"
          margin="16px 0">

          Explore Now
        </Button>

        <P1
          color={Colors.ANTIQUEGOLD}
          fontWeight={'500'}
          fontSize="2rem">
          Discover scents that define your style.
        </P1>
      </motion.div>

      <Container
        width={'100%'}
        flexDirection={'column'}>

        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}>

          <Title
            margin={'12px 0'}
            color={Colors.ANTIQUEGOLD}>

            Featured Categories.
          </Title>

          <Container
            borderRadius={'16px'}
            gap={'16px'}
            flexDirection={'row'}
            justifyContent={'space-around'}>

            <FeatureItem
              backgroundColor={Colors.WHITE}>

              <Image
                borderRadius={'16px'}
                width={'100%'}
                src={'/Assets/perfumes/oud-wood.jpg'}
                alt={'background'} />

              <Title
                color={Colors.ANTIQUEGOLD}>
                For Him
              </Title>
            </FeatureItem>

            <FeatureItem
              backgroundColor={Colors.WHITE}>

              <Image
                borderRadius={'16px'}
                width={'100%'}
                src={'/Assets/images/black-opium.jpg'}
                alt={'background'} />

              <Title
                color={Colors.ANTIQUEGOLD}>

                For Her
              </Title>
            </FeatureItem>

            <FeatureItem
              backgroundColor={Colors.WHITE}>

              <Image
                borderRadius={'16px'}
                width={'100%'}
                src={'/Assets/images/home-three.jpeg'}
                alt={'background'} />

              <Title
                color={Colors.ANTIQUEGOLD}>

                For Both
              </Title>
            </FeatureItem>

          </Container>

          <Container
            flexDirection={'column'}>

            <Title
              margin={'10px'}
              color={Colors.ANTIQUEGOLD}>

              Our Fragrances.
            </Title>

            <Container
              gap={'16px'}
              borderRadius={'8px'}
              flexDirection={'row'}
              justifyContent={'space-around'}>

              <Carousel images={imageurls} />
            </Container>
          </Container>
        </motion.div>
      </Container>

      <Container
        borderRadius={'16px'}
        flexDirection={'column'}>

        <Title
          color={Colors.ANTIQUEGOLD}>

          Fragrance is the key to our memories
        </Title>

        <P1
          margin={'0'}
          color={Colors.ANTIQUEGOLD}>
          Experience scents that leave a lasting impression.
        </P1>
      </Container>

      <Container
        margin={'12px'}
        width={'100%'}
        flexDirection={'column'}>

        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}>

          <Title
            color={Colors.ANTIQUEGOLD}>
            Get in Touch
          </Title>

          <P1
            margin={'0'}
            color={Colors.ANTIQUEGOLD}>
            Have questions? We'd love to hear from you
          </P1>
        </motion.div>
      </Container>

      <Container
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}>

        {/* Newcomers */}
      </Container>

      <Footer />
    </Container>
  );
};

export default Home;