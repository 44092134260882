import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(135deg, #4a00e0, #8e2de2);
  min-height: 100vh;
  padding: 20px;
  color: white;
`;

const Title = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #ff9a9e, #fad0c4, #fbc2eb);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-top: 20px;
`;

const IconLink = styled(motion.a)`
  font-size: 3rem;
  color: white;
  text-decoration: none;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
    color: #ff9a9e;
  }
`;

const bounceVariant = {
  hover: { scale: 1.3, rotate: 10 },
};

const fadeInVariant = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

// Main Component
const ContactUs = () => {
  return (
    <Container>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={fadeInVariant}
      >
        <Title>Connect with Classique Scents</Title>
        <p style={{ fontSize: '1.2rem', textAlign: 'center' }}>
          Follow us and stay connected on your favorite platform.
        </p>
      </motion.div>
      <IconContainer>
        <IconLink
          href="https://api.whatsapp.com/send/?phone=27694763264&text&type=phone_number&app_absent=0"
          target="_blank"
          rel="noreferrer"
          className="fab fa-whatsapp"
          whileHover="hover"
          variants={bounceVariant}
          style={{ color: '#25D366' }}
        />
        <IconLink
          href="https://www.instagram.com/classiquescents_sa?igsh=Z2NydmptYWp6cjRk&utm_source=qr"
          target="_blank"
          rel="noreferrer"
          className="fab fa-instagram"
          whileHover="hover"
          variants={bounceVariant}
          style={{ color: '#E1306C' }}
        />
        <IconLink
          href="https://www.tiktok.com/@classiquescents_sa?_r=1&_d=ebhmhbl2a9m377&sec_uid=MS4wLjABAAAAbhnjVYtAbkkmaze-ToKdo2HalQJmHAP4mQnfsib4tt-ZVI7wttoRMrUu6oBNGRox&share_author_id=7120854325626274821&sharer_language=en&source=h5_m&u_code=e2lc5b5g3h1h94&ug_btm=b0,b0&sec_user_id=MS4wLjABAAAAbhnjVYtAbkkmaze-ToKdo2HalQJmHAP4mQnfsib4tt-ZVI7wttoRMrUu6oBNGRox&utm_source=whatsapp&social_share_type=4&utm_campaign=client_share&utm_medium=ios&tt_from=whatsapp&user_id=7120854325626274821&enable_checksum=1&share_link_id=D1078677-F03A-48FC-A650-73E5E34C79D7&share_app_id=1233"
          target="_blank"
          rel="noreferrer"
          className="fab fa-tiktok"
          whileHover="hover"
          variants={bounceVariant}
          style={{ color: '#000' }}
        />
      </IconContainer>
    </Container>
  );
};

export default ContactUs;
