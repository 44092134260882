import React, { useEffect, useState } from 'react';
import { Container, P1 } from '../../../Common/Styles/Styles';
import { Colors } from '../../../Common/Constants/constants';

const Notify = ({ message, isVisible,error, onClose }) => {

  useEffect(() => {

    if (isVisible) {

      const timer = setTimeout(() => {

        onClose();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  return (
    isVisible && 

      <Container
        style={{
          position: 'fixed',
          bottom: '0',
          width: '100vw',
          left:'50%',
          transform: 'translateX(-50%)',
          backgroundColor: error ? Colors.RED : Colors.URBANGREEN,
          padding: '10px 20px',
          borderRadius: '5px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
        }}>

        <P1 color={Colors.WHITE} fontSize="14px" margin="0">
          {message}
        </P1>

      </Container>
  );
};

export default Notify;
