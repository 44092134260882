import React, {
  useEffect,
  useState
} from 'react';

import {
  Nav,
  NavLink,
  Container,
  BurgerIcon,
  Title
} from '../../../Common/Styles/Styles';

import { Colors } from '../../../Common/Constants/constants';
import { useCart } from '../../../CartContext';

const Navbar = () => {

  const { cart } = useCart();
  const cartCount = cart.reduce((sum, item) => sum + item.quantity, 0);

  const [isDisplayed, setIsDisplayed] = useState(false);

  useEffect(() => {
    
    if(cartCount > 0){
      setIsDisplayed(true);
    }
  }, [cartCount]);
  const toggle = () => {

    setIsDisplayed((prevDisplayed) => !prevDisplayed);
  };


  return (
    <Nav
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      mobileAlignItems={'flex-start'}
      margin={'0'}
      background={Colors.BLACK}
      padding={'0'}>

      <Container
        width={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        mobileFlexDirection={'row'}
        padding={'12px 0'}>


        <BurgerIcon
          padding={'0'}
          onClick={toggle}
          className={'fas fa-bars'} />

        <Title
          fontSize={'32px'}
          mobileFontSize={'32px'}
          margin={'0'}
          padding={'0'}
          color={Colors.ANTIQUEGOLD}
          to="/">Classique Scents</Title>

      </Container>

      <Container
        width={'100%'}
        mobileDisplay={isDisplayed ? 'flex' : 'none'}
        justifyContent={'center'}
        mobileFlexDirection={'row'}>
        <NavLink
          color={Colors.ANTIQUEGOLD}
          to="/shop">Shop</NavLink>
        <NavLink
          color={Colors.ANTIQUEGOLD}
          to="/wholesale">Wholesale</NavLink>
        <NavLink
          color={Colors.ANTIQUEGOLD}
          to="/cart">Cart {cartCount > 0 && `(${cartCount})`}</NavLink> 
        <NavLink
          color={Colors.ANTIQUEGOLD}
          to="/about">About</NavLink>
      </Container>
    </Nav>
  );
};

export default Navbar;
