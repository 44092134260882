import React from 'react';

import {
  Container,
  Image,
  P1
} from '../../../Common/Styles/Styles';

import {
  Colors
} from '../../../Common/Constants/constants';

const ProductsCard = ({ 
  title,
  url,
  price,
  description,
  onClick }) => {

  return (

    <Container
      width={'24%'}
      mobileWidth={'100%'}
      borderRadius={'16px'}
      margin={'10px 0'}
      justifyContent={'center'}
      alignItems={'center'}
      boxShadow={'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'}
      onClick={onClick}>

      <Container
        justifyContent={'center'}
        width={'100%'}
        flexDirection={'column'}>

        <P1
          fontSize={'1rem'}
          fontWeight={'800'}
          margin={'8px 0'}
          color={Colors.ANTIQUEGOLD}>

          {title && title.replace('-', '').toUpperCase()}
        </P1>

        <Image
          width={'90%'}
          borderRadius={'16px'}
          src={url} />
  
        <P1
          fontSize={'1rem'}
          fontWeight={'800'}
          color={Colors.ANTIQUEGOLD}
          margin={'8px 0 0 0'}>

          {description && description.replace('-', '').toUpperCase()}
        </P1>

        <P1
          fontSize={'1.5rem'}
          fontWeight={'800'}
          color={Colors.ANTIQUEGOLD}
          margin={'0'}>

          {price && `R${price}`}

        </P1>

      </Container>

    </Container>

  );
};

export default ProductsCard;