import React from 'react';
import { motion } from 'framer-motion';
import {
  Container,
  P1
} from '../../../Common/Styles/Styles';
import {
  Colors
} from '../../../Common/Constants/constants';

import { useNavigate } from 'react-router-dom';

import { routes } from '../../../Common/Routes/Routes';

const Footer = () => {

  const navigate = useNavigate()

  const navigateTerms = () => {

    navigate(routes.terms);
  };

  return (
    <Container
      width={'100%'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'center'}
      padding={'20px'}
      backgroundColor={Colors.BLACK}>

      <P1
        fontSize={'1rem'}
        color={Colors.WHITE}>
        &copy; {new Date().getFullYear()} InterTechSA. All rights reserved.
      </P1>

      <Container
        width="auto"
        flexDirection={"row"}
        mobileFlexDirection={'row'}
        justifyContent={"center"}
        alignItems={"center"}
        padding={'16px'}
        gap={'16px'}>

        <motion.a
          style={{
            fontSize: '1.5rem',
            color: 'white',
            textDecoration: 'none',
          }}
          className={'fab fa-whatsapp'}
          href='https://api.whatsapp.com/send/?phone=27694763264&text&type=phone_number&app_absent=0'
          target='_blank'
          rel="noreferrer"
          whileHover={{ scale: 1.2, rotate: 15 }}
          whileTap={{ scale: 0.9 }}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        ></motion.a>

        <motion.a
          style={{
            fontSize: '1.5rem',
            color: 'white',
            textDecoration: 'none',
          }}
          className={'fab fa-instagram'}
          href='https://www.instagram.com/classiquescents_sa?igsh=Z2NydmptYWp6cjRk&utm_source=qr'
          target='_blank'
          rel="noreferrer"
          whileHover={{ scale: 1.2, rotate: 15 }}
          whileTap={{ scale: 0.9 }}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        ></motion.a>

        <motion.a
          style={{
            fontSize: '1.5rem',
            color: 'white',
            textDecoration: 'none',
          }}
          className={'fab fa-tiktok'}
          href='https://www.tiktok.com/@classiquescents_sa?_r=1&_d=ebhmhbl2a9m377&sec_uid=MS4wLjABAAAAbhnjVYtAbkkmaze-ToKdo2HalQJmHAP4mQnfsib4tt-ZVI7wttoRMrUu6oBNGRox&share_author_id=7120854325626274821&sharer_language=en&source=h5_m&u_code=e2lc5b5g3h1h94&ug_btm=b0,b0&sec_user_id=MS4wLjABAAAAbhnjVYtAbkkmaze-ToKdo2HalQJmHAP4mQnfsib4tt-ZVI7wttoRMrUu6oBNGRox&utm_source=whatsapp&social_share_type=4&utm_campaign=client_share&utm_medium=ios&tt_from=whatsapp&user_id=7120854325626274821&enable_checksum=1&share_link_id=D1078677-F03A-48FC-A650-73E5E34C79D7&share_app_id=1233'
          target='_blank'
          rel="noreferrer"
          whileHover={{ scale: 1.2, rotate: 15 }}
          whileTap={{ scale: 0.9 }}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        ></motion.a>


      </Container>

      <P1
        margin={'0'}
        onClick={navigateTerms}
        fontSize={'1rem'}
        hoverBackground={'blue'}
        color={Colors.WHITE}>
        View Terms & Conditions.
      </P1>

    </Container>
  );
};

export default Footer;
