export const routes = {
  home: '/',
  shop:  {
    home:'/buy',
    cart: '/cart',
    checkout: '/checkout',
    cancel: '/cancel',
    notify: '/notify',
    success: '/success',
    wholesale: {
      home: '/wholesale'
    }
  },
  about: '/about',
  contact: '/contact',
  error: '/error',
  terms: '/terms'
};