import React, { useEffect, useState } from 'react';

import {
  Container,
  Title,
  P1
} from '../../../Common/Styles/Styles';

const Notify = () => {
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    setPaymentStatus('Payment Successful');
  }, []);

  return (
    <Container
      backgroundColor="#ffc107"
      padding="40px"
      flexDirection={"column"}
      justifyContent={"flex-start"}
      height={'100vh'}
      alignItems="center">

      <Title
        color="#fff"
        fontSize="3rem">

        Payment Notification
      </Title>

      {paymentStatus ? (
        <P1
          color="#fff" 
          fontSize="1.5rem"
          margin="20px 0">

          {paymentStatus}
        </P1>
      ) : (
        <P1
          color="#fff"
          fontSize="1.5rem"
          margin="20px 0">

          Checking payment status...
        </P1>
      )}
    </Container>
  );
};

export default Notify;
