import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Common/Routes/Routes";
import { useCart } from "../../CartContext";
import { Button, Container, Image, P1 } from "../../Common/Styles/Styles";
import { Colors } from "../../Common/Constants/constants";
import Notify from "../Components/Notify/Notify";

const Cart = () => {
  const { cart, handleQuantityChange, removeFromCart } = useCart();
  const navigate = useNavigate();
  const pack = parseInt(localStorage.getItem('pack'))
  const [canCheckout, setCanCheckout] = useState();
  const [error, setError] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const cartCount = cart.reduce((sum, item) => sum + item.quantity, 0);

  const handleRemove = (productId) => {
    removeFromCart(productId);
  };
  const validateCart = () => {

    if (!pack) {
      setCanCheckout(true)
      setIsNotificationVisible(false);

      return
    }

    if (pack === 10 && cartCount % 10 === 0) {
      setCanCheckout(true)
      setIsNotificationVisible(false);

      return
    }

    if (pack === 15 && cartCount % 15 === 0) {
      setCanCheckout(true)
      setIsNotificationVisible(false)

      return;
    }

    setCanCheckout(false)
    setIsNotificationVisible(true);
    setNotificationMessage(`You have selected to buy ${pack} but you only have ${cartCount} in your cart! Please note that you can buy in multiples of ${pack}`)
    setError(true);
  };

  useEffect(() => {
    validateCart()
  }, [cartCount])

  return (

    <Container
      flexDirection={'column'}>

      <P1
        textAlign={'center'}
        fontSize={"3rem"}
        fontWeight={'bold'}
        margin={"12px 0"}>
        Your Cart
      </P1>

      {cart.length === 0
        ? <Container
          height={'100vh'}
          width={'100%'}
          borderRadius={'18px'}
          backgroundColor={'orange'}>

          Your cart is empty
        </Container>

        : <Container
          flexDirection={'row'}
          width={'100%'}
          gap={'12px'}>

          {cart.map((item) => (

            <Container
              width={'15%'}
              mobileWidth={'90%'}
              padding={'12px'}
              margin={'10px 0'}

              borderRadius={'18px'}
              boxShadow={'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'}
              flexDirection={'column'}
              key={item.id}>

              <P1
                color={Colors.ANTIQUEGOLD}
                fontSize="1.25rem"
                margin="0"
                padding={'0'}>

                {item.name}
              </P1>

              <Image
                width={'90%'}
                borderRadius={'16px'}
                src={item.image} />


              <P1
                fontSize="1.25rem"
                margin="0"
                padding={'0'}
                color={Colors.ANTIQUEGOLD}>


                Price: R {item.price.toFixed(2)}
              </P1>

              <Container
                width={"100%"}
                padding={'12px'}
                flexDirection={'row'}
                mobileFlexDirection={'row'}>

                <Button
                  backgroundColor={Colors.BLACK}
                  color={Colors.WHITE}
                  padding={"8px 16px"}
                  margin={'0'}
                  onClick={() => handleQuantityChange(item.id, item.quantity - 1)}>
                  -
                </Button>

                <P1
                  color={Colors.BLACK}
                  marginBottom={'0px'}
                  margin="0px 10px">

                  {item.quantity}
                </P1>

                <Button
                  margin={'0'}
                  backgroundColor={Colors.BLACK}
                  color={Colors.WHITE}
                  padding="8px 16px"
                  onClick={() => handleQuantityChange(item.id, item.quantity + 1)}>
                  +
                </Button>
              </Container>

              <Button
                margin={'0'}
                backgroundColor={Colors.BLACK}
                color={Colors.WHITE}
                padding="8px 16px"
                onClick={() => handleRemove(item.id)}>
                Remove
              </Button>


            </Container>
          ))}



        </Container>
      }
      {cart.length > 0 &&
        <Container
          margin={'16px'}>

          <Button
            margin={'0'}
            backgroundColor={canCheckout ? Colors.ANTIQUEGOLD : 'Grey'}
            color={Colors.WHITE}
            padding={"8px 16px"}
            disabled={!canCheckout}
            onClick={() => navigate(routes.shop.checkout)}>

            Proceed to Checkout
          </Button>

        </Container>
      }

      <Notify
        message={notificationMessage}
        isVisible={isNotificationVisible}
        error={error}
        onClose={() => setIsNotificationVisible(false)}
      />
    </Container>
  );
};

export default Cart;
