import React, { useState, useEffect } from 'react';
import { Button, Container, Option, P1, Pagination, Select, Title } from '../../../Common/Styles/Styles';
import { motion } from "framer-motion";
import { useCart } from '../../../CartContext';
import { DummyProducts, packDetails } from '../../../Common/Mocks/mock';
import ProductsCard from '../../Components/ProductsCard/ProductCard';
import Footer from '../../Components/Footer/Footer';
import Notify from '../../Components/Notify/Notify';
import { Colors } from '../../../Common/Constants/constants';

const Wholesale = () => {

  const [products, setProducts] = useState(DummyProducts);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [sortOption, setSortOption] = useState('');
  const { addToCart, clearCart } = useCart();
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [selectedPack, setSelectedPack] = useState(null);
  const [error, setError] = useState(false);
  const [isReadyToBuy, setIsReadyTobuy] = useState(false);
  const pack = localStorage.getItem('pack');


  useEffect(() => {
    let sortedProducts = [...products];

    if (sortOption === 'price') {
      sortedProducts.sort((a, b) => a.price - b.price);
    } else if (sortOption === 'latest') {
      sortedProducts.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
    setProducts(sortedProducts);
  }, [sortOption]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

  const handleSortChange = (e) => {

    setSortOption(e.target.value);
  };

  const handlePageChange = (pageNumber) => {

    setCurrentPage(pageNumber);
  };

  const handlePackChange = (e) => {

    setSelectedPack(e.target.value);
    localStorage.setItem('pack', e.target.value)
  };

  const handleAddToCart = (product) => {

    if (!selectedPack) {
      setError(true);
      setNotificationMessage('Please select a wholesale pack first!')
      setIsNotificationVisible(true);

      return;
    }

    setError(false);
    setNotificationMessage(`${product.name} has been added to your cart!`);
    setIsNotificationVisible(true);
    setIsReadyTobuy(true)
    addToCart({ ...product, quantity: 1 });
  };

  useEffect(() => {
    if (!pack) {
      clearCart()
      localStorage.clear()
      return
    }
    setSelectedPack(pack);
    setIsReadyTobuy(true)
  }, [])

  return (
    <Container
      flexDirection={"column"}
      width={"100%"}>

      <Container
        width={"100%"}>
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
          style={{
            width: "100%",
            backgroundImage: "url('/Assets/images/black-opium.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "60px 20px",
            textAlign: "center",
          }}>
          <Title
            color={"White"}
            fontSize={"2.5rem"}>

            Unlock Your Earning Potential with Classique Scents SA
          </Title>
        </motion.div>
      </Container>

      <Container
        flexDirection="column"
        borderRadius={"8px"}
        padding={"12px 0 0"}>

        <P1
          color={Colors.ANTIQUEGOLD}
          fontSize="1rem"
          margin="0">

          Join our dynamic team of distributors and become your own boss! Our Hustlers Corner program empowers you to earn extra income on your own terms, at your own pace, and during your preferred working hours. Build your own channel, unlock your potential, and start achieving your financial goals with us
        </P1>
      </Container>

      <Container
        justifyContent={"center"}
        flexDirection={'column'}>

        <Container
          width={'100%'}
          flexDirection={'row'}>

          <Container
            justifyContent={"flex-end"}
            padding={"10px"}>

            <Select
              margin={"0"}
              borderRadius={"8px"}
              onChange={handlePackChange}
              value={selectedPack}
              disabled={isReadyToBuy}>
              <Option value="">Select Wholesale Pack</Option>
              <Option value="10">Hustler’s Starter Pack (10 units)</Option>
              <Option value="15">Hustler’s Power Pack (15 units)</Option>
            </Select>
          </Container>

          <Container
            justifyContent={"flex-end"}
            padding={"10px"}>

            <Select
              margin={"0"}
              borderRadius={"8px"}
              onChange={handleSortChange}
              value={sortOption}>

              <Option value="">Sort by</Option>
              <Option value="price">Price: Low to High</Option>
              <Option value="latest">Newest Arrivals</Option>
            </Select>
          </Container>
        </Container>

        {selectedPack &&

          <Container
            flexDirection="column"
            borderRadius={"8px"}
            padding={"20px"}
            width={'85%'}
            backgroundColor={Colors.ANTIQUEGOLD}
            margin={"16px 0"}>

            <Title
              fontSize={"1rem"}
              color={Colors.BLACK}
              margin={"0 0 10px 0"}>

              {packDetails[selectedPack === '10' ? 'starter-pack' : 'power-pack' ]?.title}
            </Title>

            <P1
              color={Colors.BLACK}
              fontSize="1rem"
              margin="5px 0">

              Price: {packDetails[selectedPack === '10' ? 'starter-pack' : 'power-pack' ]?.price}
            </P1>

            <P1
              color={Colors.BLACK}
              fontSize="1rem"
              margin="5px 0">

              Units: {packDetails[selectedPack === '10' ? 'starter-pack' : 'power-pack' ]?.units}
            </P1>

            <P1
              color={Colors.BLACK}
              fontSize="1rem"
              margin="5px 0">

              {packDetails[selectedPack === '10' ? 'starter-pack' : 'power-pack' ]?.description}
            </P1>

          </Container>
        }


        <Container flexDirection={"row"}
          gap={"20px"}
          flexWrap={"wrap"}
          justifyContent={"center"}>
          {currentProducts.map((product) => (
            <ProductsCard
              key={product.id}
              title={product.name}
              url={product.image}
              description={product.description}
              price={product.price}
              onClick={() => handleAddToCart(product)}
            />
          ))}
        </Container>
      </Container>

      <Pagination>
        {Array.from({ length: Math.ceil(products.length / itemsPerPage) }, (_, index) => (
          <Button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            backgroundColor={currentPage === index + 1 ? '#FFD700' : '#E0E0E0'}
            color={currentPage === index + 1 ? '#000' : '#333'}
            borderRadius={"50%"}
            width={"40px"}
            height={"40px"}
            margin={"0 8px"}>
            {index + 1}
          </Button>
        ))}
      </Pagination>

      <Footer />

      <Notify
        message={notificationMessage}
        isVisible={isNotificationVisible}
        error={error}
        onClose={() => setIsNotificationVisible(false)}
      />
    </Container>
  );
};

export default Wholesale;
