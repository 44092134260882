import React,
{
  useState,
  useEffect
} from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Button,
  Container,
  Option,
  Pagination,
  Select,
  Title
} from '../../Common/Styles/Styles';

import ProductsCard from '../Components/ProductsCard/ProductCard';

import Footer from '../Components/Footer/Footer';

import { motion } from "framer-motion";

import { routes } from '../../Common/Routes/Routes';

import { DummyProducts } from '../../Common/Mocks/mock';
import { useCart } from '../../CartContext';
import Notify from '../Components/Notify/Notify';

const Products = () => {

  const { clearCart } = useCart(); 
  const [products, setProducts] = useState(DummyProducts);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [sortOption, setSortOption] = useState('');
  const { addToCart } = useCart();
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const pack = localStorage.getItem('pack');


  useEffect(() => {
    let sortedProducts = [...products];

    if (sortOption === 'price') {
      sortedProducts.sort((a, b) => a.price - b.price);
    } else if (sortOption === 'latest') {
      sortedProducts.sort((a, b) => new Date(b.date) - new Date(a.date));
    }
    setProducts(sortedProducts);
  }, [sortOption]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

  const handleSortChange = (e) => {

    setSortOption(e.target.value);
  };

  const handlePageChange = (pageNumber) => {

    setCurrentPage(pageNumber);
  };

  const handleAddToCart = (product) => {

    setNotificationMessage(`${product.name} has been added to your cart!`);
    setIsNotificationVisible(true); 
    addToCart({ ...product, quantity: 1 });
  };

  useEffect(( ) => {

    if(pack){
      setIsNotificationVisible(true);
      setNotificationMessage(`Cart has been cleared, we can't have wholesale and individual sale in one basket!`);
      clearCart()
      localStorage.clear()
    }
  },[])

  return (

    <Container
      flexDirection={"column"}
      width={"100%"}>

      <Container
        width={"100%"}>

        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 1 }}
          style={{
            width: "100%",
            backgroundImage: "url('/Assets/images/black-opium.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            padding: "60px 20px",
            textAlign: "center",
          }}>

          <Title
            color={"White"}
            fontSize={"2.5rem"}>

            Discover Your Perfect Fragrance
          </Title>

          <Title
            color={"White"}
            fontSize={"1.2rem"}>

            Explore a curated collection tailored to your style.
          </Title>
        </motion.div>
      </Container>

      <Container
        width={"100%"}
        flexDirection={"column"}
        justifyContent={"center"}>

        <Container 
        justifyContent={"flex-end"}
        padding={"10px"}
        margin={"0"}
        width={"100%"}>

          <Select
            margin={"0"}
            borderRadius={"8px"}
            onChange={handleSortChange}
            value={sortOption}>

            <Option value="">Sort by</Option>
            <Option value="price">Price: Low to High</Option>
            <Option value="latest">Newest Arrivals</Option>
          </Select>
        </Container>

        <Container
          flexDirection={"row"}
          gap={"20px"}
          flexWrap={"wrap"}
          justifyContent={"center"}>

          {currentProducts.map((product) => (
            <ProductsCard
              key={product.id}
              title={product.name}
              url={product.image}
              description={product.description}
              price={product.price}
              onClick={() => handleAddToCart(product)}
            />
          ))}
        </Container>
      </Container>

      <Pagination>

        {Array.from({ length: Math.ceil(products.length / itemsPerPage) }, (_, index) => (

          <Button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            backgroundColor={currentPage === index + 1 ? '#FFD700' : '#E0E0E0'}
            color={currentPage === index + 1 ? '#000' : '#333'}
            borderRadius={"50%"}
            width={"40px"}
            height={"40px"}
            margin={"0 8px"}>

            {index + 1}
          </Button>
        ))}
      </Pagination>

      <Footer />

      <Notify
        message={notificationMessage}
        isVisible={isNotificationVisible}
        error={false}
        onClose={() => setIsNotificationVisible(false)} 
      />
    </Container>
  );
};

export default Products;
